import classNames from 'classnames';

export function Section(props: { children: React.ReactNode }) {
  return <div className="space-y-related w-full">{props.children}</div>;
}

export function Column(props: { children: React.ReactNode }) {
  return <div className="flex flex-col space-y-2">{props.children}</div>;
}

export function Row(props: { children: React.ReactNode; between?: boolean; breakpoint?: 'sm' | 'md' }) {
  return (
    <div
      className={classNames('w-full flex', {
        'space-x-related items-center': !props.breakpoint,
        'justify-between': props.between,
        'sm:items-center max-sm:flex-col sm:space-x-related': props.breakpoint === 'sm',
        'sm:space-x-related': !props.between && props.breakpoint === 'sm',
        'max-sm:space-y-related': props.breakpoint === 'sm' && !props.between,
        'md:items-center max-md:flex-col md:space-x-related': props.breakpoint === 'md',
        'md:space-x-related': !props.between && props.breakpoint === 'md',
        'max-md:space-y-related': props.breakpoint === 'md' && props.between,
      })}
    >
      {props.children}
    </div>
  );
}

export default function Sections(props: { children: React.ReactNode }) {
  return <div className="space-y-unrelated w-full">{props.children}</div>;
}
